import * as jose from 'jose';

export async function modernValidate(key: string, state: string) {
  try {
    const pk = await jose.importSPKI(key, 'ES256')
    const verified = await jose.jwtVerify(state, pk, { clockTolerance: '1s' })
    return verified.payload
  } catch (e) {
    console.error(e)
    return false;
  }
  
}
