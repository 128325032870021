import React, {useState} from 'react';
import Loading from "./Loading";
import './Enroll.css'

import {useNavigate} from "react-router-dom";
import {JWTPayload} from "jose";

type RecoveryCodeProps = {
  state?: JWTPayload
  recoveryCode: string
}

const RecoveryCode = (params: RecoveryCodeProps) => {
  const {recoveryCode} = params;
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const handleCheckbox = () => {
    setChecked(!checked);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!checked) {
      return
    }

    const hash = window.location.hash
    const redirect = params.state?.rd as string|undefined
    if (redirect && hash?.substring(1) !== "email-enroll") {
      window.location.href = redirect;
    } else {
    navigate('/success')
    }
  }

  const handleCopyCode = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()		
    copyToClipboard()
  }

  const copyToClipboard = async () => {
    try {
      
      if (navigator.clipboard !== undefined) {
        await navigator.clipboard.writeText(recoveryCode);
      } else if ((window as any).clipboardData) { // Internet Explorer
        (window as any).clipboardData.setData("Text", recoveryCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return recoveryCode ? (
    <main className="main">
      <section className="c7c07fbff _prompt-box-outer c4dc36cba">
        <div className="c25d1b5ec cd43f3464">
          <div className="cb29232b3">
            <header className="cb7fe8136 c8ecd7007">
              <div title="MYOB" id="custom-prompt-logo"></div>
              <img className="cb8b5079c c600c7027" id="prompt-logo-center" src="https://login.myob.com/Images/myob-logo.svg" alt="MYOB" />
              <h1 className="c690d3e44 cc977329f">Save a recovery code</h1>
              <div className="cc2570856 cdaf61c72">
                <p className="c7b122490 cdffe5edf">Copy this recovery code and keep it somewhere safe. You can use this code to log in when you don't have your phone handy.</p>
              </div>
            </header>
            <div className="c389aac0b cf34ac957">
              <form className="form" onSubmit={onSubmit}>
                <div className="c48ccce47 c2505b870">
                  <div className="input multiline">{recoveryCode}</div>
                </div>
                <button type="button" className="c99b527ce c27520c76 js-required c39b3b9c2 c90f72e8d ca17e0a8d c1769a48c" name="copy-code" onClick={handleCopyCode}>
                  <span>Copy code</span>
                </button>
                <div className="cc166236b c2a54c974 c6aaa0f4f">
                  <input className="cbb982cc1 c2a674e46" id="saved" name="saved" type="checkbox" onChange={handleCheckbox} required />
                  <label className="c6735359b c70534480" htmlFor="saved">
                    <span className="c7b122490 cdffe5edf">I’ve saved a copy of this code</span>
                  </label>
                </div>
                <div className="c972fd828">
                  <button type="submit" name="action" value="default" className="c99b527ce c488a9d61 c39b3b9c2 c3ae61280 c0291a3ec" data-action-button-primary="true">Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  ) : <Loading/>;
}

export default RecoveryCode;
