import React, {useEffect, useState} from 'react';
import * as r from 'ramda';
import Loading from "./Loading";
import {AuthConfig} from "../types";
import Enrollment from './Enroll';
import {initialEmailEnrollment} from "./auth0";

type StartEnrollProp = {
  token: string
  email: string
  authConfig: AuthConfig
}

const StartEnroll = (params: StartEnrollProp) => {
  const {token, email, authConfig} = params;
  const {domain} = authConfig
  const [code, setCode] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [, setSendingError] = useState('');

  useEffect(() => {
    initialEmailEnrollment(setCode, setRecoveryCode, setSendingError)(token, domain, email)
  }, [token, email, domain])

  if(r.isEmpty(code) || r.isEmpty(recoveryCode)) {
    return <Loading/>
  }

  return <Enrollment {...({...params, code, recoveryCode})} />
}

export default StartEnroll;
