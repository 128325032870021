import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import qs from 'qs';
import Loading from "./Loading";
import {AuthConfig, Authenticator, AuthenticatorState} from "../types";
import {fetchAndCleanEmailAuth, initialEmailEnrollment} from "./auth0";

import '../assets/theme.css'
import './Enroll.css'
import RecoveryCode from './RecoveryCode';
import {obfuscateEmail} from './helper';
import * as r from "ramda";

type EnrollmentProps = {
  token: string
  email: string
  code: string
  recoveryCode: string
  authConfig: AuthConfig
}

const Enrollment = (params: EnrollmentProps) => {
  const {token, email, authConfig} = params;
  const {domain, clientId: client} = authConfig
  const [pin, setPin] = useState("");
  const [keep, setKeep] = useState<AuthenticatorState[]>([])
  const [code, setCode] = useState(params.code)
  const [recoveryCode, setRecoveryCode] = useState(params.recoveryCode)
  const [result, setResult] = useState(false);
  const [error, setError] = useState('');
  const [sendingError, setSendingError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);


  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const verifyCode = (token: string) => (oobCode: string, pinCode: string) => {
      const data = qs.stringify({
        'grant_type': 'http://auth0.com/oauth/grant-type/mfa-oob',
        'mfa_token': token,
        'oob_code': oobCode,
        'binding_code': pinCode,
        'client_id': client
      });
      const config = {
        method: 'post',
        url: `https://${domain}/oauth/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };
      setError('')
      axios(config)
        .then(function (response) {
          setResult(true)
        })
        .catch(function (error) {
          console.log(error);
          setError('Incorrect or expired code.')
        });
    }
    verifyCode(token)(code, pin)
  }

  useEffect(() => {
    if (r.find((a: Authenticator) => a.oob_channel ==='email', keep)) {
      window.location.href = "https://myob.com"
    }
  }, [keep])

  const handleResetClick = (inputRef: React.RefObject<HTMLInputElement>) => async () => {
    inputRef.current?.focus();
    setPin('')
    setError('')
    setSendingError('')
    await fetchAndCleanEmailAuth(setKeep)(token, authConfig.domain)
    await initialEmailEnrollment(setCode, setRecoveryCode, setSendingError)(token, authConfig.domain, email)
  }

  const goBack = () => {
    window.history.back()
  }

  if (result) {
    return <RecoveryCode recoveryCode={recoveryCode} state={authConfig.state} />;
  }

  return code ? (
    <main className="main">
      <section className="c7c07fbff _prompt-box-outer c4dc36cba">
        <div className="c25d1b5ec cd43f3464">
          <div className="cb29232b3">
            <header className="cb7fe8136 c8ecd7007">
              <div title="MYOB" id="custom-prompt-logo" ></div>
              <img className="cb8b5079c c600c7027" id="prompt-logo-center" src="https://login.myob.com/Images/myob-logo.svg" alt="MYOB" />
                <h1 className="c690d3e44 cc977329f">Setup email two factor</h1>
              <div className="cc2570856 cdaf61c72">
                <p className="c7b122490 cdffe5edf">We've sent an authentication code to your email - <strong>{obfuscateEmail(email)}</strong></p>
              </div>
            </header>
            <div className="c389aac0b cf34ac957">
              {
                sendingError ? (<div id="prompt-alert" className="caaabb6d5 cb32fbf00" data-error-code="too-many-emails"
                                    aria-live="assertive" aria-atomic="true">
                    <p className="ca7576bb5">{sendingError}</p>
                </div>) : <></>
              }
              <div className="cb15f584f cb4ca98c6">
                <div className="ca5ec30f2">
                  <form method="POST" className="c6d55c8e1 ca86a79a0" data-form-primary="true" onSubmit={onSubmit}>
                    <div className="cb15f584f cb4ca98c6">
                      <div className="ca5ec30f2">
                        <div className="input-wrapper _input-wrapper">
                          <div className={"c48ccce47 cb03101f6 text c7789a39d c6825e831 " + (pin.length > 0 ? " c144f0892 " : "") + (error.length > 0 ? " c48ccce47 c1400dcd1 ": "") } data-action-text="" data-alternate-action-text="">
                            <input 
                              className="input"
                              name="code" 
                              id="code" 
                              type="text" 
                              value={pin} 
                              required 
                              autoComplete="true" 
                              autoCapitalize="none" 
                              spellCheck="false" 
                              autoFocus
                              ref={inputRef}
                              onChange={e => e.target.value.length < 7 ? setPin(e.target.value) : pin}
                            />
                            <div className="c3b1179e1 js-required cec5382e9 cce66dbd3" data-dynamic-label-for="code" aria-hidden="true">
                              Enter your authentication code
                            </div>
                          </div>
                          {
                            (pin.length && error.length > 0) ?
                                (<span id="error-element-code" className="ulp-input-error-message" data-error-code="invalid-code">
                                  <span className="ulp-input-error-icon" role="img" aria-label="Error"></span>{error}</span>)
                                : <></>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="c972fd828">
                      <button type="submit" name="action" value="default" className="c99b527ce c488a9d61 c39b3b9c2 c3ae61280 c648b1820" data-action-button-primary="true">Verify code</button>
                    </div>
                  </form>
                  <div className="ca0de09ab c1d730d64">Didn't receive an email? 
                    <form method="POST" className="cb54d7a0a">
                      <button type="button" name="action" onClick={handleResetClick(inputRef)} aria-label="" value="resend-code" className="link-colour c4999f2c2 _link-resend-code c99e688b2">Resend</button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="ulp-alternate-action center _alternate-action ">
                <a href="https://myob.com/help" target="_blank" className="link-colour" rel="noreferrer">I need help</a>
              </div>
              <div className="ulp-alternate-action center _alternate-action ">
                <button type="button" name="action" onClick={goBack} aria-label="" value="try-another-method" className="link-colour c4999f2c2 c99e688b2">Try another method</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  ) : <Loading/>;
}

export default Enrollment;
