import axios from "axios";
import * as r from "ramda";
import React, {SetStateAction} from "react";
import {AuthenticatorState} from "../types";

export const fetchAndCleanEmailAuth = (setKeep: React.Dispatch<SetStateAction<AuthenticatorState[]>>) => async (
    token: string,
    domain : string,

) => {
    const cleanUpInactiveAuthenticator = async (token: string): Promise<AuthenticatorState[]> => {
        const config = {
            method: 'get',
            url: `https://${domain}/mfa/authenticators`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        };
        return axios(config)
            .then(function (response: {data: AuthenticatorState[]}) {
                const [toBeRemoved, toBeKept] = r.partition(r.allPass([(x:AuthenticatorState)  => r.or(r.isNil(x.oob_channel), x.oob_channel === 'email'), (x:AuthenticatorState) => !x.active]), response.data);
                setKeep(toBeKept)
                return toBeRemoved
            })
            .catch(function (error) {
                return []
            });
    }

    const removeAuthenticators = (token: string) => async (authenticators: AuthenticatorState[]) => {
        const config = ({id}: AuthenticatorState) => ({
            method: 'delete',
            url: `https://${domain}/mfa/authenticators/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return r.isEmpty(authenticators) ? [] : axios.all(r.map((target: AuthenticatorState) =>
            axios(config(target))
                .catch(err => console.error('error in remove unconfirmed authenticator.', err)), authenticators));
    }
    const toBeRemoved = await cleanUpInactiveAuthenticator(token)
    await removeAuthenticators(token)(toBeRemoved)
}

export const initialEmailEnrollment = (setCode: React.Dispatch<SetStateAction<string>>,
                                       setRecoveryCode: React.Dispatch<SetStateAction<string>>,
                                       setSendingError: React.Dispatch<SetStateAction<string>>) => async (
    token: string,
    domain: string,
    email:string
) => {
    const data = JSON.stringify({
        "authenticator_types": ["oob"],
        "oob_channels": ["email"],
        "email": email
    });

    const config = {
        method: 'post',
        url: `https://${domain}/mfa/associate`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        data
    };
    return axios(config)
        .then(function (response) {
            setCode(r.pathOr('', ['data', 'oob_code'], response))
            setRecoveryCode(r.pathOr('', ['data', 'recovery_codes', '0'], response))
        })
        .catch(function (error) {
            switch(r.pathOr<number>(200, ['response', 'status'], error)) {
                case 429:
                    setSendingError('You have exceeded the maximum number of emails per hour. Wait a few minutes and try again.')
                    break
                default:
                    setSendingError('We are having trouble communicating our backend. Wait a few minutes and try again.')
            }
        });
}