import React from "react";
import { Route, Routes } from "react-router-dom";

import './App.css';
import {Config} from "./types";
import {EnrollEmailRoute} from "./components/EnrollEmailRoute";
import Success from "./components/Success";

function App(config: Config) {
  return (<div id="app" className="d-flex flex-column h-100">
      <Routes>
        <Route path="/email-enroll" element={<EnrollEmailRoute {...config} />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<>enrollment cancelled.</>} />
        <Route path="/error" element={<>Error!</>} />
        <Route path="/*" element={<>404 Not found!</>} />
      </Routes>
    </div>);
}

export default App;
