import jwt_decode from "jwt-decode";
import { propIs } from 'ramda';
import type { JWTPayload } from "jose";

export async function validateState(key: string, state: string, userid?: string, crypto?: Crypto) {
  let payload: JWTPayload
  if (!crypto?.subtle) {
    payload = jwt_decode(state)
    // all the best to IE users
    if (propIs(String, 'rd', payload)) {
      return /\.myob(dev)?\.com$/.test(new URL(payload.rd).host) ? payload: false
    }
  } else {
    const {modernValidate} = require('./verifyStateModern')
    payload = await modernValidate(key, state)
  }
  return userid && payload?.sub === userid ? payload : false
}
