import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import AcquireToken from './AcquireToken';
import Loading from "./Loading";
import React, {useCallback, useEffect, useState} from "react";
import {AuthConfig} from "../types";
import type {JWTPayload} from "jose";
import {validateState} from './verifyState'

type LandingParams = {
  publicKey: string
  authConfig: AuthConfig
}

function RouteAuthenticationGuard(params: LandingParams) {
  const {publicKey, authConfig} = params;
  const [validState, setHasValidState] = useState<JWTPayload | false | undefined>(undefined)
  const {
    isLoading,
    user
  } = useAuth0();

  const searchParams = new URL(window.location.href).searchParams;
  const state = searchParams.get("state") || ""

  const checkValidState = useCallback(async () => {
    const payload = await validateState(publicKey, state, user?.sub, window.crypto)
      setHasValidState(payload)
  }, [publicKey, state, user])

  useEffect(() => {
    checkValidState()
      .catch(console.error);
  }, [checkValidState])

  if (isLoading || validState === undefined) {
    return <Loading />;
  }

  if (!validState) {
    window.location.href = "https://myob.com"
    return <Loading />;
  }
  document.title = "Enroll Email MFA - MYOB";
  return <AcquireToken {...authConfig} state={validState}/>
}

export default withAuthenticationRequired(RouteAuthenticationGuard, {
  onRedirecting: () => <Loading />,
});
