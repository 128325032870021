import React from 'react';
import './Success.css'

const Success = () => {
  return (
    <main className="main success-page">
      <section className="c7c07fbff _prompt-box-outer ce8dc6d31">
        <div className="c25d1b5ec">
          <div className="c389aac0b cec64186c" data-event-id="">
            <div className="">
              <span className="c2daf5bbb success-lock"></span>
            </div>
            <section className="">
              <h1 className="ce8dc32d9">Success!</h1>
              <p className="c53a5b673">Your account now has an extra layer of security.</p>
            </section>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Success;

