import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CleanUpInactiveEmail from "./CleanUpInactiveEmail";
import Loading from "./Loading";
import {AuthConfig} from "../types";

export default function AcquireToken(authConfig: AuthConfig) {
  const [token, setToken] = useState<string | undefined>(undefined)
  const { getAccessTokenSilently, user } = useAuth0();
  useEffect(() => {
    if (!token) {
      (async () => {
        try {
          const token = await getAccessTokenSilently()
          setToken(token)
        } catch (e) {
          console.error(e);
        }
      })()
    }
  });

  if (token && user?.email) {
    return <CleanUpInactiveEmail {...{token, email: user?.email, authConfig}} />
  }

  return <Loading />
}
