import React, {useEffect, useState} from 'react';
import * as r from 'ramda';
import Loading from "./Loading";
import StartEnroll from "./KickOff";
import {AuthConfig, Authenticator, AuthenticatorState} from "../types";
import { fetchAndCleanEmailAuth } from "./auth0"

type CleanUpParams = {
  token: string
  email: string
  authConfig: AuthConfig
}

const CleanUpInactiveEmail = (params: CleanUpParams) => {
  const {token, authConfig} = params;

  const [keep, setKeep] = useState<AuthenticatorState[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    fetchAndCleanEmailAuth(setKeep)(token, authConfig.domain).then(() => setLoading(false))
  }, [token, authConfig.domain])

  if (loading) {
    return <Loading />
  }

  if (r.find((a: Authenticator) => a.oob_channel ==='email', keep)) {
    window.location.href = "https://myob.com"
  }

  return <StartEnroll {...params} />
}

export default CleanUpInactiveEmail;
