const obfuscatePart = (part: string) => part.slice(0, 4) + (part.length > 3 ? '*'.repeat(part.length - 4) : '');

export const obfuscateEmail = (email: string) => {
    const regex = new RegExp('^.+@.+$');

    if (email && regex.test(email)) {
        return email.split('@').map(obfuscatePart).join('@');
    }
    
    return '';
}